import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../../components/seo'
import IdeasListingLayout from '../../layouts/ideas/IdeasListingLayout'
import VirtualizedPostsList from '../../components/virtualized-posts-list/VirtualizedPostsList'
import './ideas-archive.scss'
import Wysiwyg from '../../components/wysiwyg/Wysiwyg'

export default class IdeasArchive extends Component {
    render() {
        const { page, stories, tutorials } = this.props.data
        let ideas = [].concat(stories.edges, tutorials.edges).sort((a, b) => {
            if (a.node.dateCompare > b.node.dateCompare) return -1
            if (a.node.dateCompare < b.node.dateCompare) return 1
            return 0
        })
        const featured = ideas[0].node
        const featured_link =
            featured.__typename === 'wordpress__POST'
                ? `/ideas/stories/${featured.slug}`
                : `/ideas/tutorials/${featured.slug}`
        const featured_excerpt =
            featured.content.replace(/(<([^>]+)>)/gi, '').substring(0, 100) +
            '...'

        //pop the first.
        ideas = ideas.slice(1)
        return (
            <IdeasListingLayout>
                <SEO
                    yoast={page.yoast}
                    title={page.title}
                    description={page.content}
                    dateModified={page.modified}
                    datePublished={page.date}
                />
                <header className="IdeasHeader d-flex">
                    {featured.featuredMedia &&
                        featured.featuredMedia.localFile && (
                            <section className="image">
                                <Link to={featured_link}>
                                    <Img
                                        fluid={
                                            featured.featuredMedia.localFile
                                                .childImageSharp.fluid
                                        }
                                    />
                                </Link>
                            </section>
                        )}
                    <section className="text d-flex flex-column align-items-start justify-content-center px-yd">
                        <span className="post-type">
                            {featured.__typename === 'wordpress__POST'
                                ? 'Stories'
                                : 'Tutorials'}
                        </span>
                        <Link to={featured_link}>
                            <h2
                                className="title"
                                dangerouslySetInnerHTML={{
                                    __html: featured.title,
                                }}
                            />
                        </Link>
                        <Wysiwyg className="excerpt">
                            {featured_excerpt}
                        </Wysiwyg>
                        <Link
                            className="type-link border-bottom border-dark"
                            to={featured_link}
                        >
                            read more
                        </Link>
                    </section>
                </header>
                <section className="IdeasArchive container-fluid px-yd">
                    <div className="ul-ideas">
                        <VirtualizedPostsList posts={ideas} />
                    </div>
                </section>
            </IdeasListingLayout>
        )
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        page: wordpressPage(id: { eq: $id }) {
            slug
            title
            content
            ...YoastPage
        }
        stories: allWordpressPost {
            edges {
                node {
                    ...IdeasStoryListItemFragment
                }
            }
        }
        tutorials: allWordpressWpTutorials {
            edges {
                node {
                    ...IdeasTutorialListItemFragment
                }
            }
        }
    }
`
